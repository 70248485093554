import axios from 'axios';

$(document).ready(function () {
    $('#reset_button').click(function (e) {
        e.preventDefault();
        $('#search_form').find('input[type=text], input[type=number]').val('');
        $('select').not('.nullbooleanselect').val('');
        $('select.nullbooleanselect').val('unknown');
        $('#id_active').val('1');
        $('select').trigger('change');
    });

    $('#id_username').focus();

    $('#exportButtons button[data-type]').click(function(){
        const exportType = {'_export': $(this).data('type')};
        let url = window.location.href;
        url += ( url.indexOf('?') >= 0 ? '&' : '?' ) + $.param(exportType);
        window.location.href = url;
    });

    $('select#id_brand').change(function(){
        const model = $('select#id_model');
        model.empty();
        model.append(new Option('---------', ''));
        model.trigger('change');

        if(!$(this).val()){
            return;
        }
        const url = `/fleet/models/options?brand_id=${$(this).val()}`;

        axios.get(url)
        .then(response => {
            response.data.forEach(([id, text]) => {
                model.append(new Option(text, id));
            });
            model.trigger('change');
        })
        .catch(error => {
            // handle error
            console.log(error);
        });
    });
});