import '../scss/admin_index.scss';

require('jquery-ui-stable/jquery-ui.min');
require('bootstrap/dist/js/bootstrap.bundle');
require('jquery-ui/ui/plugin');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/datepicker');

require('select2');
// select2(window, jQuery);
require('./select2.es');

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
// import '@fortawesome/fontawesome-free/js/regular';
// import '@fortawesome/fontawesome-free/js/brands';

import ajaxSendMethod from './ajax';
import handleMessageDismiss from './messages';

import './misc';
import './off-canvas';

import './formsets';
import './purchaseForm';
import './serviceForm';

$(() => {

    $.fn.select2.defaults.set('language', 'es');

    $('select:not(.ajax-select)').select2({
        language: 'es',
        width: '100%',
    });

    $('.ajax-select').select2({
        language: 'es',
        width: '100%',
        ajax: {
            data: function (params) {
                const query = {
                    q: params.term,
                    page: params.page || 1
                };
                return query;
            },
            delay: 250
        },
        minimumInputLength: 1
    });

    $(document).ajaxSend(ajaxSendMethod);

    // Topbar active tab support
    $('.topbar li').removeClass('active');

    const classList = $('body').prop('class').split(/\s+/);
    $.each(classList, (index, item) => {
        const selector = `ul.nav li#tab_${item}`;
        $(selector).addClass('active');
    });


    $('#account_logout, .account_logout').click(e => {
        e.preventDefault();
        $('#accountLogOutForm').submit();
    });

    handleMessageDismiss();
});


import './cutoms';
