require('./plugins/jquery.formset');

($ => {
  $('#serviceFormset tbody tr').formset({
    addText: '<i class="fa fa-plus"></i>',
    deleteText: '<i class="fa fa-minus"></i>',
    prefix: 'service_formset',
    added: function (e) {
      $(e).find('p.invalid-feedback').remove();
      $(e).find('.is-invalid').removeClass('is-invalid');
      $(e).find('select').select2({
        language: 'es',
        width: '100%',
      });
    },
  });

  $('#productFormset tbody tr').formset({
    addText: '<i class="fa fa-plus"></i>',
    deleteText: '<i class="fa fa-minus"></i>',
    prefix: 'product_formset',
    added: function (e) {
      $(e).find('p.invalid-feedback').remove();
      $(e).find('.is-invalid').removeClass('is-invalid');
      $(e).find('select').select2({
        language: 'es',
        width: '100%',
      });
    },
  });

})(jQuery);