require('./plugins/jquery.formset');
const axios = require('axios').default;
const qs = require('querystring');

import {formData, resetForm, removeFeedback} from './utils';

($ => {
    const total = {
        sequence: 0,
        elements: {},
        subtotalSpan: $('#subtotal'),
        totalSpan: $('#total'),
        next: function () {
            this.sequence += 1;
            return this.sequence;
        },
        add: function (element) {
            const el = $(element);
            const elId = this.next();
            el.attr('data-id', elId);
            const quantityInput = el.find('.quantity')[0];
            const priceUnit = el.find('.price_unit')[0];
            const onChange = () => {
                const quantity = $(quantityInput).val();
                const price = $(priceUnit).val();
                const total = (quantity * price);
                const priceTotal = el.find('.price_total')[0];
                $(priceTotal).val(total);
                this.elements[elId] = total;
                this.updateTotal();
            };
            $(quantityInput).on('change', onChange);
            $(priceUnit).on('change', onChange);
            $(priceUnit).trigger('change');
        },
        updateTotal: function () {
            const total = Number(this.getTotal()).toLocaleString('es');
            this.subtotalSpan.text(total);
            this.totalSpan.text(total);
        },
        del: function (element) {
            delete this.elements[$(element).attr('data-id')];
            this.updateTotal();
        },
        getTotal: function () {
            return Object.entries(this.elements).reduce(
                (sum, element) => {
                    return sum + element[1];
                }, 0);
        }
    };
    const selector = '#purchase_form tbody tr';
    $(selector).formset({
        addText: '<i class="fa fa-plus"></i>',
        deleteText: '<i class="fa fa-minus"></i>',
        prefix: 'orderline_set',
        added: function (e) {
            $(e).find('p.invalid-feedback').remove();
            $(e).find('.is-invalid').removeClass('is-invalid');
            $(e).find('select').val('').select2({
                language: 'es',
                width: '100%',
            });
            total.add(e);
        },
        removed: function (e) {
            total.del(e);
        }
    });

    $(selector).each(function (i, e) {
        total.add(e);
    });

    $('#submit-button').on('click', function () {
        $('#purchase_form').submit();
    });

    const addProductModal = $('#addProductModal');
    const addProductForm = $('#addProductForm');

    addProductModal.on('hidden.bs.modal', function () {
      resetForm(addProductForm);
    });

    addProductForm.addFeedback = function(errors) {
        Object.entries(errors).map(([field, errorList]) => {
            const input = addProductForm.find(`#id_${field}`);
            input.addClass('is-invalid');
            errorList.map(error => {
                const feedback = $(`<span class="invalid-feedback"><b>${error}</b></span>`);
                input.parent().append(feedback);
            });
        });
    };

    addProductForm.on('submit', function (e) {
        e.preventDefault();
        removeFeedback(addProductForm);
        const url = addProductForm.attr('action');
        const data = formData(this);
        axios(url, {
            method: 'post',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(data),
        }).then(result => {
            const product = result.data;
            const newOption = new Option(product.name, product.id, false, false);
            $('#purchase_form .select.product').append(newOption).trigger('change');
            addProductModal.modal('hide');
        }).catch(error => {
            addProductForm.addFeedback(error.response.data);
        });
    });

    const updatePayDateVisibility = (paymentMethod, payDate) => {
        const value = paymentMethod.val();
        if (value === 'credit') {
            payDate.removeClass('hidden');
        } else {
            payDate.addClass('hidden');
        }
    };

    const paymentMethod = $('#id_payment_method');
    const payDate = $('#div_id_pay_date').addClass('hidden');

    updatePayDateVisibility(paymentMethod, payDate);

    paymentMethod.change(function() {
        updatePayDateVisibility(paymentMethod, payDate);
    });

})(jQuery);
