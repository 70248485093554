require('./plugins/jquery.formset');

($ => {
    const inlineFormset = $('.formset-form .inline_formset');
    const prefix = inlineFormset.data('prefix');
    inlineFormset.formset({
        addText: '<i class="fa fa-plus"></i>',
        deleteText: '<i class="fa fa-minus"></i>',
        prefix: prefix,
        added: function (e) {
            $(e).find('p.invalid-feedback').remove();
            $(e).find('.is-invalid').removeClass('is-invalid');
            $(e).find('select').val('').select2({
                language: 'es',
                width: '100%',
            });
        },
    });
})(jQuery);
