export function formData(FormElement) {
    const formData = new FormData(FormElement);
    const ConvertedJSON = {};
    for (const [key, value] of formData.entries()) {
        ConvertedJSON[key] = value;
    }
    return ConvertedJSON;
}

export function removeFeedback(formElement){
    const form = $(formElement);
    form.find('.is-invalid').removeClass('is-invalid');
    form.find('.invalid-feedback').remove();
}

export function resetForm(formElement) {
    const form = $(formElement);
    form.find('input[type=text], input[type=number], textarea').val('');
    form.find('select').not('.nullbooleanselect').val('');
    form.find('select.nullbooleanselect').val('unknown');
    form.find('select').trigger('change');
    removeFeedback(form);
}
